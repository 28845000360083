.Game {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  background-color: #95bdff;
  max-height: 900px;
  min-height: 800px;
}

.Game-Playground {
  display: flex;
  flex: 10;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 5%;
  justify-content: center;
}

.Game-TopBar {
  display: flex;
  margin: 3%;
  justify-content: space-between;
  flex: 1;
}

.Game-TopBar .GameName {
  display: inline-flex;
}

.Game-TopBar .RightPanel {
  display: inline-flex;
  width: 40%;
  justify-content: space-between;
}

.Game-TopBar .ShowPlayers:hover {
  background-color: rgb(231, 231, 231);
}

.Game-TopBar .GoBack:hover {
  background-color: rgb(231, 231, 231);
}

.Game-TopBar .GameName .Name {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: white;
  border: solid black 2px;
  font-weight: bold;
  padding-left: 100%;
  padding-right: 100%;
}

.Game-TopBar .ShowPlayers {
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: white;
  border: solid black 2px;
  font-weight: bold;
  padding-left: 3%;
  padding-right: 3%;
  min-width: 0px;
}

.Game-TopBar .GoBack {
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: white;
  border: solid black 2px;
  font-weight: bold;
  padding-left: 3%;
  padding-right: 3%;
}

@media screen and (max-width: 500px) {
  .Game {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    background-color: #95bdff;
    max-height: 900px;
    min-height: 835px;
    padding-left: 15%;
    padding-right: 15%;
  }

  .Game-Playground {
    margin-top: 2%;
  }

  .Game-TopBar {
    display: flex;
    flex-direction: column;
    margin: 3%;
  }

  .Game-TopBar .GameName {
    flex: 1;
  }

  .Game-TopBar .RightPanel {
    flex: 1;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }

  .Game-TopBar .GameName .Name {
    flex: 1;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: white;
    border: solid black 2px;
    font-weight: bold;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .Game-TopBar .ShowPlayers {
    justify-content: center;
    flex: 1;
    align-items: center;
    border-radius: 100px;
    background-color: white;
    border: solid black 2px;
    font-weight: bold;
    padding-left: 3%;
    padding-right: 3%;
    min-width: 0px;
  }

  .Game-TopBar .GoBack {
    justify-content: center;
    align-items: center;
    flex: 1;
    border-radius: 100px;
    background-color: white;
    border: solid black 2px;
    font-weight: bold;
    padding-left: 3%;
    padding-right: 3%;
  }
}
