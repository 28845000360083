.Grid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-image: url("./map.png");
  background-repeat: no-repeat;
  width: max-content;
  padding-left: 0px;
  margin-left: -3px;
  margin-top: -3px;
}

.TransformComponent-module_container__3NwNd {
  border: solid black 3.2px;
}

.tools {
  position: absolute;
  padding-left: 2px;
  padding-top: 2px;
  width: 30px;
  display: flex;
  flex-direction: column;
}

.Grid .row {
  display: flex;
  flex-direction: row;
}

.Grid #cell {
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px dotted #a0a0a0;
}

.Game-Playground .Btn_NextPlayer {
  position: absolute;
  bottom: calc(0% - 0px);
  border-radius: 100px;
  background-color: white;
  border: solid black 2px;
  font-weight: bold;
  padding-left: 3%;
  padding-right: 3%;
}

.obstacle {
  background-color: rgba(0, 0, 0, 0.5);
}

.walkable {
  background-color: rgba(255, 255, 255, 0.5);
}

.loot {
  background-color: rgba(0, 37, 248, 0.5);
}

.lootWeapon {
  background-color: rgba(238, 2, 2, 0.5);
}

.lootArmor {
  background-color: rgba(248, 244, 0, 0.5);
}

.player {
  background-color: rgba(214, 25, 173, 0.5);
}

.player1 {
  background-color: rgba(214, 25, 173, 1);
}

.player2 {
  background-color: rgb(82, 158, 105);
}

.player3 {
  background-color: rgba(214, 25, 173, 1);
}

.player4 {
  background-color: rgba(214, 25, 173, 1);
}

.player5 {
  background-color: rgba(214, 25, 173, 1);
}

.deadPlayer {
  background-color: rgb(0, 0, 0);
  color: aliceblue;
}

.Grid #cell.actifPlayer {
  border: 2px solid black;
}
