.Input {
    height: 25px;
    padding: 10px;
    border: none;
    border-radius: 35px;
    background-color: #f5f3f3;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    outline: none;
  }

  .btn {
    padding: 1% 15%;
    align-items: center;
    max-width: 250px;
    border-radius: 100px;
    background-color: white;
    border: solid black 2px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: bold;
    box-sizing: border-box;
  }

.btn1 {
  padding: 1% 15%;
  margin: 10px;
  border-radius: 100px;
  background-color: white;
  border: solid black 2px;
  cursor: pointer;
  text-decoration: none !important;
  font-weight: bold;
  box-sizing: border-box;
  min-width: 180px;
  max-width: 250px;
}

.btn:hover{
    transition: transform 0.2s ease-in-out;
    transform: scale(1.1) !important;
}

.btn1:hover{
  transition: transform 0.2s ease-in-out;
  transform: scale(1.1) !important;
}