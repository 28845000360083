.accueil {
  font-size: 1.6em;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 350px;
}

.accueil__title {
  display: flex;
  flex: 1;
  color: #ffffff;
  text-shadow: 11px 7px 4px rgba(0, 0, 0, 0.98);
  margin-bottom: 1%;
  margin-top: 1%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.accueil__body {
  background-color: #95bdff;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 4;
  justify-content: space-around;
}
.accueil__left {
  display: flex;
  flex: 1;
  margin-left: 2%;
  margin-right: 2%;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 2%;
}

.accueil__left > button {
  background: #ffffff;
  height: 70px;
  border: 1px solid #000000;
  border-radius: 100px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: bold;
}

.accueil__left .CustomButton {
  background: #ffffff;
  width: 100%;
  height: 70px;
  border: 1px solid #000000;
  border-radius: 100px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: bold;
}

.accueil__right {
  display: flex;
  margin-left: 2%;
  margin-right: 2%;
  flex: 1;
  flex-direction: column;
  border: 1px solid #000000;
  justify-content: center;
  background: #ffffff;
  border-radius: 50px;
}

.accueil__right .parties_list {
  margin: 3%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
}

.accueil__right a {
  background: #eeeeee;
  border: 1px solid #000000;
  border-radius: 100px;
  font-weight: bold;
  width: 80%;
  align-self: center;
  color: #000000;
  text-decoration: none;
}
.accueil__right p {
  text-decoration: underline;
  font-weight: bold;
}

@media screen and (max-width: 500px) {
  .accueil__body {
    background-color: #95bdff;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 4;
    justify-content: space-around;
  }
}
